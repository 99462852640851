/* App.css */

@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: "Kanit", sans-serif;
  background-color: #252835;
  max-width: 1440px;
  margin: 0 auto;
}

.text-des {
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.01em;
  text-align: center;
  text-decoration: none;
}

.btn-text {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.01em;
}

.blue-btn {
  background-color: #5f77f4;
}

.yellow-btn {
  background-color: #f4c16b;
  color: #252835 !important;
}

.pink-btn {
  background-color: #f355a1;
}

.navbar {
  height: 88px;
  padding: 0 156px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .nav-btn {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 24px;
    border: 1px solid #f9f9fc;
    color: #f9f9fc;
    border-radius: 64px;
  }
}

.hero-container {
  padding: 0 156px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 88px);

  .text-container {
    color: #f9f9fc;
    max-width: 665px;
    .header {
      font-size: 64px;
      font-weight: 400;
      line-height: 80px;
      text-align: left;
      margin-bottom: 16px;
    }
    .info {
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0.008em;
      text-align: left;
      margin-bottom: 40px;
    }

    .btn-container {
      display: flex;
      gap: 24px;

      &:last-child {
        margin-top: 24px;
      }

      .hero-btn {
        padding: 18px 24px;
        text-decoration: none;
        color: #ffffff;
        display: flex;
        gap: 12px;
        border-radius: 64px;
        align-items: center;
        justify-content: center;
        .btn-logo {
          display: block;
        }
      }
    }
  }

  .hero-icon {
    display: block;
  }
}

.form-container {
  height: calc(100vh - 88px);
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    background-color: #fff;
    max-width: 600px;
    padding: 40px 56px;
    border-radius: 8px;
    .form-header {
      font-size: 28px;
      font-weight: 500;
      line-height: 36.4px;
      text-align: left;
      color: #252835;
      margin-bottom: 16px;
    }

    .form-dropdown {
      color: #868da4;
      padding: 13px;
      padding-left: 0;
      font-size: 18px;
      font-weight: 400;
      line-height: 28px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #868da4;
      margin-top: -12px;
      /* margin-bottom: 40px; */
      cursor: pointer;
    }

    .margin-bottom {
      margin-bottom: 40px !important;
    }

    .margin-bottom-sm {
      margin-bottom: 24px;
    }

    .radio-label {
      font-family: Kanit !important;
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0.008em;
      text-align: left;
    }

    .btn-container {
      display: flex;
      gap: 24px;

      .btn-form {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;

        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #ffffff;
        border-radius: 64px;
        padding: 12px 0;
        cursor: pointer;
      }
    }
  }
}

.arrow-icon {
  margin-left: auto;
  rotate: 180deg;
  transition: all 0.2s ease-in;
}
.arrow-icon-rotate {
  rotate: 0deg;
}

.textField {
  input,
  li {
    font-family: "Kanit", sans-serif !important;
    color: #868da4;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
  }
  & label.Mui-focused {
    color: #868da4;
  }
  & .MuiOutlinedInput-root {
    border-color: #868da4;

    &.Mui-focused fieldset {
      border-color: #868da4;
    }
  }

  .MuiInput-input::after {
    border-color: #868da4 !important;
  }
}

.game {
  text-align: center;
  height: calc(100vh - 88px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.game-container {
  background-color: #fff;
  max-width: 600px;
  border-radius: 16px;
  padding: 60px 72px;
}

.board {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  width: 456px;
  height: 456px;
  margin-bottom: 0px;
}

.board-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.cell-container {
  /* margin: 1px; */
  width: 100%;
  height: 100%;
}

.cell {
  /* font-size: 2rem !important; */
  border: 1px solid #dce1ed !important;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  cursor: pointer;
  background-color: #f9f9fc !important;
  border-radius: 8px !important;
  min-width: 100% !important;
  padding: 0px !important;
}

.normal-cell {
  width: 111px;
  height: 111px;
  min-width: 111px !important;
}

.flex-cell {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 8px !important;
}
.o-button {
  color: #eee845 !important;
  /* border: 1px solid #f4c16b !important; */
  background-color: #ffeed9 !important;
  background-image: url(/src/components/SVGs/CircleIcon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.x-button {
  padding: 0 !important;
  color: #7bbcff !important;
  /* border: 1px solid #5f77f4 !important; */
  background-color: #f0efff !important;
  background-image: url(/src/components/SVGs/CrossIcon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.o-button-active {
  background-image: url(/src/components/SVGs/CircleActiveIcon.svg);
}

.x-button-active {
  background-image: url(/src/components/SVGs/CrossActiveIcon.svg);
}
.cell:hover {
  background-color: #f0f0f0;
}

.last-move {
  background-color: #f3f3f3 !important;
}

.btn-container-board {
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  margin-bottom: 40px;

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.01em;

    &:hover {
      .hover-icon {
        transition: all 0.2s ease-in-out;
        fill: #252835;

        path {
          stroke: #fff !important;
        }
      }
    }
  }

  .btn-pink {
    color: #f859a5;

    &:hover {
      .hover-icon-pink {
        transition: all 0.2s ease-in-out;
        fill: #f859a5;

        path {
          stroke: #fff !important;
        }
      }
    }
  }
}

.customDialog {
  filter: blur(10);
  border-radius: 16px;
  .MuiPaper-root {
    border-radius: 16px;
    width: 550px !important;
  }
}

.dialog-header {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: 32px !important;
  color: #252835;
}

.modal-footer {
  padding: 20px 24px;
}

.image-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  p {
    font-size: 24px !important;
  }
}

.numberToWinMobile {
  display: none !important;
}

.numberToWin {
  display: block !important;
}

.textFieldInfo {
  display: none;
}

@media only screen and (max-width: 850px) {
  body {
    max-width: 600px;
    margin: 0 auto;
  }

  .btn-text {
    font-size: 16px;
    line-height: 28px;
  }

  .navbar {
    height: 72px;
    padding: 0 16px;
    .nav-btn {
      gap: 0px;
      padding: 0;
      border: none;

      .nav-btn-text {
        display: none;
      }
    }
  }

  .hero-container {
    padding: 0 16px;
    height: calc(100vh - 72px);

    .text-container {
      max-width: 100%;
      .header {
        font-size: 40px;
        font-weight: 400;
        line-height: 48px;
        text-align: left;
        margin-bottom: 16px;
      }
      .info {
        font-size: 16px;
        font-weight: 300;
        line-height: 24px;
        letter-spacing: 0.008em;
        text-align: left;
        margin-bottom: 40px;
      }

      .btn-container {
        display: flex;
        gap: 24px;

        &:last-child {
          margin-top: 24px;
        }

        .hero-btn {
          padding: 18px 24px;
          text-decoration: none;
          color: #ffffff;
          display: flex;
          gap: 12px;
          border-radius: 64px;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .hero-icon {
      display: none;
    }
  }
}

@media only screen and (max-width: 600px) {
  body {
    max-width: 100%;
    width: 100%;
    margin: 0;
  }

  .pink-btn {
    background-color: transparent;
    border: 1px solid #f859a5;
    color: #f859a5 !important;
  }

  .hero-container {
    .text-container {
      max-width: 100%;
      .header {
        font-weight: 600;
      }
      .info {
        margin-bottom: 32px;
      }

      .btn-container {
        display: flex;
        gap: 24px;
        flex-direction: column;
        position: relative;

        &:last-child {
          margin-top: auto;
          position: absolute;
          bottom: 24px;
          width: calc(100% - 32px);
        }

        .hero-btn {
          padding: 10px 24px;
          .btn-logo {
            display: none;
          }
        }
      }
    }

    .hero-icon {
      display: none;
    }
  }

  .form-container {
    height: calc(100vh - 72px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: #fff;
    padding: 32px 16px;
    .form {
      max-width: 100%;
      padding: 0;
      border-radius: 0;
      .form-header {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 16px;
      }

      .btn-container {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }
  }

  .game {
    height: calc(100vh - 72px);
    background-color: #fff;
    justify-content: flex-start;
    padding: 32px 16px;
  }

  .game-container {
    max-width: 100%;
    width: 100%;
    border-radius: 0;
    padding: 0;
  }

  .board {
    width: 100%;
    height: 100%;
    margin-bottom: 0px;
  }

  .normal-cell {
    width: 96px;
    height: 96px;
    min-width: 96px !important;
  }

  .modal-footer {
    flex-direction: column;
    gap: 24px;
    justify-content: flex-start;
  }

  .btn-pink {
    display: flex;
    flex-direction: row-reverse;
  }

  .numberToWinMobile {
    display: block !important;
    position: relative !important;
  }

  .numberToWin {
    display: none !important;
  }

  .textFieldInfo {
    display: block;
    position: absolute !important;
    padding: 0 !important;
    top: 14px;
    right: 0;
  }

  .customDialog {
    .MuiPaper-root {
      width: 100% !important;
    }
  }
}

@keyframes slide-up {
  0% {
    height: 0;
  }
  100% {
    height: 154px;
  }
}

@keyframes slide-down {
  0% {
    height: 154px;
  }
  100% {
    height: 0;
  }
}
